import Vue from 'vue';
import Vuex from 'vuex';

import './projectConfig';
import App from './App.vue';

import * as dayjs from 'dayjs'

const timezone = require("dayjs/plugin/timezone");
const utc = require("dayjs/plugin/utc");
require('dayjs/locale/en');

import router, { removeRouteByName, resetRouter } from '@pazion/pazion-core/src/router';
import store from '@pazion/pazion-core/src/store/index';
import 'vue-material-design-icons/styles.css';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import { config } from 'vuex-module-decorators';
import Rollbar from 'rollbar';
// Set rawError to true by default on all @Action decorators
config.rawError = true;

Vue.config.productionTip = false;

Vue.prototype.$orderListingType = 'order';
Vue.prototype.$copyOrder = false;
Vue.prototype.$editOrder = false;
Vue.prototype.$orderDetails = true;
Vue.prototype.$createDelivery = false;
Vue.prototype.$showDeliveryDialog = false;
Vue.prototype.$editOrderLineStatus = false;
Vue.prototype.$stockManagement = false;
Vue.prototype.$productManagement = false;
Vue.prototype.$manufactureManagement = false;
Vue.prototype.$dossierManagement = false;
Vue.prototype.$orderDossierManagement = false;
Vue.prototype.$accountManagement = false;
Vue.prototype.$orderlineMeasureCalculation = false;
Vue.prototype.$orderQuickMenu = false;
Vue.prototype.$measureCalculationUnit = 'mm';
Vue.prototype.$offerManagement = false;
Vue.prototype.$invoiceManagement = true;
Vue.prototype.$contractManagement = false;
Vue.prototype.$transactionManagement = false;

// if true - order create with simple ref (current datetime)
Vue.prototype.$orderAutoReference = true;

dayjs.extend(timezone);
dayjs.extend(utc)
dayjs.locale('en');
import './assets/scss/main.scss'

if (Vue.prototype.$stockManagement != true)
    removeRouteByName('stock');

if (Vue.prototype.$productManagement != true)
    removeRouteByName('products');

if (Vue.prototype.$dossierManagement != true)
    removeRouteByName('dossier');

if (Vue.prototype.$accountManagement != true)
    removeRouteByName('accounts');

if (Vue.prototype.$invoiceManagement != true)
    removeRouteByName('invoices');

if (Vue.prototype.$offerManagement != true)
    removeRouteByName('offers');

if (Vue.prototype.$contractManagement != true)
    removeRouteByName('contracts');

if (Vue.prototype.$transactionManagement != true)
    removeRouteByName('transactions');

//rewrite our route array
resetRouter();

Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.ROLLBAR_POST_CLIENT_ITEM_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    environment: 'production',
    checkIgnore: function (isUncaught, args, payload) {
        return window.location.hostname === 'localhost'
    },
    payload: {
        client: {
            javascript: {
                code_version: '1.0'
            }
        }
    }
});



Vue.config.errorHandler = (err, vm, info) => {
    //@ts-ignore
    vm.$rollbar.error(err);
    throw err; // rethrow
};


Vue.use(Vuex);
Vue.use(VueRouter);
new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

