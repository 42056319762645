
import Vue from 'vue';
import PazLoader from '@pazion/pazion-core/src/components/utils/PazLoader.vue';
import PazFooter from '@pazion/pazion-core/src/components/layout/PazFooter.vue';
import PazNavigation from '@pazion/pazion-core/src/components/layout/PazNavigation.vue';
import {Component, Watch} from 'vue-property-decorator';
import {authentication} from '@pazion/pazion-core/src/store';

@Component({
	components: {
		PazNavigation,
	  PazFooter,
	  PazLoader
	},
})
export default class PazionApp extends Vue {
	public showNavigation: boolean = false;
	public showDrawer: boolean = false;


	public created() {
		authentication.verifyUser();
		this.showNavigation = authentication.isAuthenticated;
	}

	get isUserLoggedIn() {
		return authentication.isAuthenticated;
	}

	get isLoading() {
		if(!authentication.isAuthenticated){
			return false;
		}
		return false;
	}

	@Watch('isUserLoggedIn')
	private changeNavigation(authenticated: boolean) {
		this.showNavigation = authenticated;
	}
	get doShowNavigation(){
		return this.showNavigation;
	}

}
