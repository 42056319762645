import {addRoutes, redirectRouteToBackend, removeRouteByName} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";
import {addChildRoute, addRoute} from "@pazion/pazion-core/src/router";
import About from "./views/About.vue";

addRoutes(getCatalogRoutes());



addChildRoute("settings", {
	path: "/boldotcom/settings",
	name: "boldotcom",
	component: About,
	meta: {
		"show-in-nav": true,
		"redirect-to-backend": true,
		roles: ["user"]
	}
});

addChildRoute("settings", {
	path: "/exactonline/settings",
	name: "exactonline",
	component: About,
	meta: {
		"show-in-nav": true,
		"redirect-to-backend": true,
		roles: ["user"],
		sort: 800
	}
});

//removeRouteByName("returns");
removeRouteByName("stock");

redirectRouteToBackend('returns', true);
